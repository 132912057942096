import {createAction} from "../reduxHelper";
import axios from "./../axios";
export const getElection = createAction('GET_ELECTION', function(slug) {
    return axios.get('/election/'+ slug + '/');
});

export const voteCandidate = createAction('VOTE_CANDIDATE', function(slug, formData) {
    let data = JSON.stringify({
        ...formData
    })
    return axios.post('/election/' + slug + '/vote', data, { cache: false});
});

export const resetElection = createAction('RESET_ELECTION', function() {
    return true;
});