import React from 'react';
import {Link} from "react-router-dom";

class Privacy extends React.Component {

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        return <div className="row">
            <div className="col-xs-12">
                <h1 className="w-headline">
                    <span>Datenschutz</span>
                    <Link to="/" className="w-back">
                        <svg className="w-back-icon" aria-hidden="true" role="img">
                            <svg viewBox="0 0 1024 1024" width="100%" height="100%">
                                <path d="M830.8 483.4l-34-34c-0.4-0.4-0.8-0.8-1.4-1.2l-437.2-437.2c-15.6-15.6-41-15.6-56.6 0l-34 34c-15.6 15.6-15.6 41 0 56.6l410.4 410.2-410 410c-15.6 15.6-15.6 41 0 56.6l34 34c15.6 15.6 41 15.6 56.6 0l472.2-472.4c15.4-15.6 15.4-41 0-56.6z"></path> </svg>
                        </svg>
                    </Link>
                </h1>

                <h2>Nutzung und Weitergabe personenbezogener Daten</h2>
                <p>
                    Jegliche Nutzung Ihrer personenbezogenen Daten erfolgt nur zu den genannten Zwecken und in dem zur
                    Erreichung dieser Zwecke erforderlichen Umfang.
                    Übermittlungen personenbezogener Daten an staatliche Einrichtungen und Behörden erfolgen nur im Rahmen
                    zwingender nationaler Rechtsvorschriften oder wenn die Weitergabe im Fall von Angriffen auf unsere
                    Netzinfrastruktur zur Rechts- oder Strafverfolgung erforderlich ist.
                </p>

                <h2>Protokollierung</h2>
                <p>
                    Bei jedem Zugriff eines Nutzers auf eine Seite aus der Wahlseite werden Daten über diesen Vorgang in einer
                    Protokolldatei gespeichert. Je nach verwendetem Zugriffsprotokoll beinhaltet der Protokolldatensatz Angaben
                    mit folgenden Inhalten:
                </p>

                <ul>
                    <li>IP-Adresse des anfordernden Rechners,</li>
                    <li>Name der angeforderten Datei,</li>
                    <li>Datum und Uhrzeit der Anforderung,</li>
                    <li>vom anfordernden Rechner gewünschte Zugriffsmethoden/Funktionen,</li>
                    <li>Zugriffsstatus des Web-Servers (Datei übertragen, Datei nicht gefunden, Kommando nicht ausgeführt,
                        etc.) sowie
                    </li>
                    <li>die URL, von der aus die Datei angefordert/die gewünschte Funktion veranlasst wurde,</li>
                    <li>Betriebssystem und Browsertyp bzw. Browsereinstellungen.</li>
                </ul>

                <p>
                    Es gibt für uns keine Möglichkeit, IP-Adresse und eventuell vorhandene personenbezogene Daten zu verknüpfen.
                    Die gespeicherten Daten werden ausschließlich zu Zwecken der Identifikation und Nachverfolgung unzulässiger
                    Zugriffsversuche/Zugriffe auf den Web-Server sowie für statistische Auswertungen wie Besucherzahlen und
                    Seitenpopularität verwendet. Die Auswertung erfolgt nur durch autorisierte Mitarbeiter von uns.
                </p>

                <h2>Links zu Webseiten anderer Anbieter</h2>
                <p>
                    Unsere Webseiten können Links zu Webseiten anderer Anbieter enthalten. Wir weisen darauf hin, dass diese
                    Datenschutzerklärung ausschließlich für diese Webseite gilt. Wir haben keinen Einfluss darauf und
                    kontrollieren nicht, dass andere Anbieter die geltenden Datenschutzbestimmungen einhalten.
                </p>

                <h2>Einbeziehung, Gültigkeit und Aktualität der Datenschutzerklärung</h2>
                <p>
                    Mit der Nutzung unserer Webseite willigen Sie in die vorab beschriebene Datenverwendung ein. Die
                    Datenschutzerklärung ist aktuell gültig und datiert vom 01.11.2015.
                    Durch die Weiterentwicklung unserer Webseite oder die Implementierung neuer Technologien kann es notwendig
                    werden, diese Datenschutzerklärung zu ändern. Die Stadt Wuppertal behält sich vor, die Datenschutzerklärung
                    jederzeit mit Wirkung für die Zukunft zu ändern. Wir empfehlen Ihnen, sich die aktuelle Datenschutzerklärung
                    von Zeit zu Zeit erneut durchzulesen.
                </p>

            </div>
        </div>
    }
}

export default Privacy;

