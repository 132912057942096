import axios from 'axios';
import { cacheAdapterEnhancer } from 'axios-extensions';
import config from './config';

export default axios.create({
    baseURL: config.url,
    timeout: 15000,
    adapter: cacheAdapterEnhancer(axios.defaults.adapter),
    headers: {'Content-Type': 'application/json', 'Cache-Control' : 'no-cache', 'Access-Control-Allow-Origin': '*'},
    withCredentials: true
});