import React from 'react';
import PropTypes from "prop-types";

class CaptchaIcon extends React.Component {

    static propTypes = {
        index: PropTypes.number.isRequired,
        group: PropTypes.object.isRequired
    };

    // Set default props
    static defaultProps = {
        onClick: function() {}
    };

    constructor(props) {
        super(props);
        this.state = {
            selected: false
        }
    }

    handleClick(event) {
        this.setState((prevState) => ({
            selected: !prevState.selected
        }));
        this.props.onClick(this.props.index);
    }

    selected() {
        if (this.state.selected) {
            return <i className="captchaSelected fa fa-check-circle fa-2x text-success"></i>
        }
    }

    render() {
        let iconClassName = ["captchaIcon"];

        if(this.state.selected) {
            iconClassName.push("selected");
        }

        const foregroundClassName = ['foreground captchaImage', this.props.group['f']];
        const backgroundClassName = ['background captchaImage', this.props.group['b']];
        return (
            <div className="captchaContainer" onClick={this.handleClick.bind(this)}>
                <span className={iconClassName.join(' ')}>
                    <span className={foregroundClassName.join(' ')}></span>
                    <span className={backgroundClassName.join(' ')}></span>
                </span>
                { this.selected() }
            </div>
        );
    }
}

export default CaptchaIcon;