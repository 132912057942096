import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {Link, useHistory, useParams} from 'react-router-dom';
import config from "../../config";
import Moment from 'react-moment';
import Swipe from 'react-easy-swipe';
import findIndex from 'lodash/findIndex'

import {getCandidate} from "../../actions/candidateActions";
import LinkButton from './../LinkButton';

import getFilteredCandidates from "./filter/candidateFilter";

const IMAGE_URL = process.env.REACT_APP_IMAGE_URL

const renderEducationalLevel = ({candidate}) => {
    if (candidate.educationalLevel !== '') {
        return <p>{candidate.educationalLevel}</p>
    }
    return null
}

const renderVoting = ({election, candidate}) => {
    if (election.state !== 'open') {
        return <div><p>
            <strong>ACHTUNG! Alle Kandidat:innen können vom {<Moment
                format="DD.MM.YYYY HH:mm">{election.start}</Moment>} Uhr bis zum {<Moment
                format="DD.MM.YYYY HH:mm">{election.end}</Moment>} Uhr an dieser Stelle gewählt werden.
            </strong>
        </p>
            <LinkButton className={'w-button w-button-default w-button-icon-left'} to="/kandidaten">zurück zur Liste</LinkButton>
        </div>
    }

    return <div>
        <div>
            <LinkButton className="w-button w-button-primary w-button-icon-left" to={`/waehlen/${candidate.id}`}
                        title={`${candidate.firstname} jetzt wählen`}>{candidate.firstname} jetzt wählen</LinkButton>
        </div>
        <div>
            <LinkButton className={'w-button w-button-default w-button-icon-left'} to="/kandidaten">zurück zur Liste</LinkButton>
        </div>
    </div>
}


export const Candidate = () => {
    const {id} = useParams()
    const dispatch = useDispatch();
    const history = useHistory();

    useEffect(() => {
        dispatch(getCandidate(config.slug, id))
        window.parent.postMessage({scroll: {y: 100}}, '*');
    }, [id]);



    const isLoading = useSelector(state => state.election.getCandidate.loading);
    const election = useSelector(state => {
        return state.election.getElection.payload !== null ? state.election.getElection.payload : null
    });
    const candidate = useSelector(state => {
        return state.election.getCandidate.payload !== null ? state.election.getCandidate.payload : null
    })

    const candidates = useSelector(state => {
        const filter = state.election.filterCandidates.payload !== null ? state.election.filterCandidates.payload.params : null;
        let candidates = []

        if (state.election.getCandidates.payload !== null) {
            candidates = getFilteredCandidates(state.election.getCandidates.payload.candidates, filter);
        }

        return candidates
    });


    let swipedMovedPixel = 0;

    const onSwipeMove = (position, event) => {
        swipedMovedPixel = position.x;
    }

    const onSwipeEnd = (event) => {

        if (swipedMovedPixel !== 0) {
            const currentIndex = findIndex(candidates, {id: candidate.id})

            if (swipedMovedPixel > 75) {
                let prevIndex = currentIndex - 1;
                if (prevIndex < 0) {
                    prevIndex = candidates.length - 1;
                }
                history.push(`/kandidat/${candidates[prevIndex].id}`)

            } else if (swipedMovedPixel < -75) {
                const maxIndex = candidates.length - 1;
                let nextIndex = currentIndex + 1;

                if (nextIndex > maxIndex) {
                    nextIndex = 0;
                }
                history.push(`/kandidat/${candidates[nextIndex].id}`)
            }
        }
    }

    if (isLoading || candidate == null || (candidate != null && candidate.id != id)) {
        return <div>Lade den/die Kandidat:in...</div>
    }

    return (
        <Swipe
            onSwipeMove={onSwipeMove}
            onSwipeEnd={onSwipeEnd}
        >
            <div className="candidate no-select">
                <h1 className="w-headline">
                    <span className="w-headline-inner">
                        <Link to="/kandidaten" className="w-back">
                            <svg className="w-back-icon" aria-hidden="true" role="img">
                                <svg viewBox="0 0 1024 1024" width="100%" height="100%">
                                    <path
                                        d="M830.8 483.4l-34-34c-0.4-0.4-0.8-0.8-1.4-1.2l-437.2-437.2c-15.6-15.6-41-15.6-56.6 0l-34 34c-15.6 15.6-15.6 41 0 56.6l410.4 410.2-410 410c-15.6 15.6-15.6 41 0 56.6l34 34c15.6 15.6 41 15.6 56.6 0l472.2-472.4c15.4-15.6 15.4-41 0-56.6z"></path>
                                </svg>
                            </svg>
                        </Link>
                        <span className="w-text">{candidate.firstname} {candidate.lastname}</span>
                    </span>
                </h1>
                <div className="row">
                    <div className="col-xs-12 col-sm-6" style={{maxWidth: "500px"}}>
                        <img className="img-rounded img-color img-responsive" src={`${IMAGE_URL}${candidate.imagePath}`}
                             alt={candidate.firstname}/>
                    </div>
                    <div className="col-xs-12 col-sm-6 profile">
                        <p>Alter: {candidate.age}</p>
                        <p>Bezirk: {candidate.district.name}</p>
                        {renderEducationalLevel({candidate})}
                        <p>
                            <strong>Veränderungswünsche</strong><br/>
                            <span>{candidate.manifesto}</span>
                        </p>
                        {renderVoting({election, candidate})}
                    </div>
                </div>
            </div>
        </Swipe>
    );
}
export default Candidate