import React from 'react'
import {connect} from "react-redux";

class Snippet extends React.Component {

    render() {
        if(this.props.identifier && this.props.snippets[this.props.identifier]) {
            if(this.props.raw === true) {
                return this.props.snippets[this.props.identifier].content;
            }


            return <div
                className={'snippet'}
                dangerouslySetInnerHTML={{__html: this.props.snippets[this.props.identifier].content}}
            />
        }

        return null;
    }
}

const mapStateToProps = state => {
    return {
        snippets: state.election.getSnippets.payload || {}
    };
};

export default connect(mapStateToProps, {})(Snippet);