import {createAction} from "../reduxHelper";
import axios from "./../axios";
import {createSlice} from "@reduxjs/toolkit";

export const getCandidates = createAction('GET_CANDIDATES', function(slug) {
    return axios.get('/election/'+ slug + '/candidates');
});

export const getCandidate = createAction('GET_CANDIDATE', function(slug, id) {
    return axios.get('/election/' + slug + '/candidate/' + id);
});

const initialState = { pageY: 0 }

const candidatePageYSlice = createSlice({
    name: 'candidatePageYSlice',
    initialState,
    reducers: {
        setPageY(state, {payload}) {
            state.pageY = payload
        },
    },
})

export const { setPageY } = candidatePageYSlice.actions
export const pageYReducer = candidatePageYSlice.reducer