import React from 'react';
import { Link } from 'react-router-dom';
import config from "../../config";

class Imprint extends React.Component {

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        return <div className="row">
            <div className="col-xs-12">
                <h1 className="w-headline">
                    <span>Impressum</span>
                    <Link to="/" className="w-back">
                        <svg className="w-back-icon" aria-hidden="true" role="img">
                            <svg viewBox="0 0 1024 1024" width="100%" height="100%">
                                <path d="M830.8 483.4l-34-34c-0.4-0.4-0.8-0.8-1.4-1.2l-437.2-437.2c-15.6-15.6-41-15.6-56.6 0l-34 34c-15.6 15.6-15.6 41 0 56.6l410.4 410.2-410 410c-15.6 15.6-15.6 41 0 56.6l34 34c15.6 15.6 41 15.6 56.6 0l472.2-472.4c15.4-15.6 15.4-41 0-56.6z"></path> </svg>
                        </svg>
                    </Link>
                </h1>
                <p>
                    Diese Website wird betrieben von:<br/>
                    Stadt Wuppertal, vertreten durch den Oberbürgermeister<br/>
                    Herrn Andreas Mucke<br/>
                    Johannes-Rau-Platz 1<br/>
                    42275 Wuppertal<br/>
                    Telefon +49 202 563 0<br/>
                    Telefax +49 202 563 8199<br/>
                    <a href="mailto:stadtverwaltung@stadt.wuppertal.de">stadtverwaltung@stadt.wuppertal.de</a>
                </p>

                <h2>Inhaltlich und redaktionell Verantwortliche</h2>
                <p>
                    Stadt Wuppertal<br/>
                    Fachbereich Jugend & Freizeit – Jugendamt<br/>
                    Alexanderstr.18<br/>
                    42269 Wuppertal<br/>
                    Telefon +49 202 563 5637<br/>
                    Telefax +49 202 563 4665<br/>
                    E-Mail: <a href="mailto:sigrid.moellmer@stadt.wuppertal.de">sigrid.moellmer@stadt.wuppertal.de</a>
                </p>

                <h2>Konzeption, Gestaltung und Umsetzung</h2>
                <p>
                    PETERS UND KONSORTEN
                    Forsmannstraße 22a<br/>
                    22303 Hamburg<br/>
                    E-Mail: <a href="mailto:info@petersundkonsorten.de">info@petersundkonsorten.de</a><br/>
                    und<br/>
                    Internetteam der Stadt Wuppertal<br/>
                    E-Mail: <a href="mailto:info@stadt.wuppertal.de">internet@stadt.wuppertal.de</a>
                </p>

                <h2>Haftung für Inhalte</h2>
                <p>
                    Unser Angebot enthält Links zu externen Webseiten Dritter, auf deren Inhalte wir keinen Einfluss haben.
                    Deshalb können wir für diese fremden Inhalte auch keine Gewähr übernehmen. Für die Inhalte der
                    verlinkten Seiten und die Eintragungen ist stets der/die jeweilige Anbieter/-in oder Betreiber/-in der
                    Seiten verantwortlich. Die verlinkten Seiten wurden zum Zeitpunkt der Verlinkung auf mögliche
                    Rechtsverstöße überprüft.
                </p>

                <h2>Rechtshinweis</h2>
                <p>
                    Die Stadt Wuppertal ist Herausgeberin der Webseite www.wuppertal.de.
                    Sie bemüht sich, auf dieser Webseite nur vollständige und richtige Informationen zu veröffentlichen,
                    kann aber keine Haftung oder Garantie für Aktualität, Richtigkeit und Vollständigkeit der Informationen
                    und Darstellungen übernehmen.
                    Die Stadt Wuppertal haftet nicht für direkte oder indirekte Schäden, einschließlich entgangenen Gewinns,
                    die mit der Nutzung des Informations- und Funktionsangebotes dieser Webseite in Verbindung gebracht
                    werden können. Das gilt insbesondere auch für die ausgewiesenen Links (Verbindungen) zu anderen
                    (externen) Webseiten, auf die die Herausgeberin keinen Einfluss hat und für deren Inhalt die Stadt
                    Wuppertal keine Verantwortung trägt.
                    Für Internetseiten Dritter, auf die per Hyperlink verwiesen wird, tragen die jeweiligen Anbieter die
                    Verantwortung.
                </p>

                <h2>Urheberrecht</h2>
                <p>
                    Der Inhalt der Internetseiten der Stadt Wuppertal ist urheberrechtlich geschützt.
                    Die Vervielfältigung von Informationen oder Daten hieraus, insbesondere die Verwendung von Texten,
                    Textteilen oder Bildmaterial, bedarf der vorherigen Zustimmung der Stadt Wuppertal. Das Wappen der Stadt
                    Wuppertal, das Logo der Stadt Wuppertal und der Domain-Name wuppertal.de genießen namensrechtlichen
                    Schutz.
                    Wer das Urheber-, Marken- oder Namensrecht verletzt, muss mit der Geltendmachung von Unterlassungs- und
                    Schadensersatzansprüchen durch den Rechteinhaber, bei Verletzungen des Urheber- und Markenrechts auch
                    mit Strafverfolgung rechnen.
                </p>

                <h2>Datenschutz</h2>
                <p>
                    Diese Seite schützt die Daten nach den Vorschriften der geltenden Datenschutzgesetze. Weitere
                    Informationen zum Datenschutz sowie die Datenschutzerklärung finden Sie <Link to="/datenschutzerklaerung">hier</Link>
                </p>
            </div>
        </div>
    }
}
export default Imprint;