function createActionNameStates(actionName) {
    if (typeof actionName !== 'string') {
        throw new Error('actionName must be a string');
    }

    const actionNameUpper = actionName.toUpperCase();
    return {
        request: actionNameUpper + '_REQUEST',
        success: actionNameUpper + '_SUCCESS',
        failure: actionNameUpper + '_FAILURE'
    };
}

export const initialState = {
    payload: null,
    loading: false,
    error: null,
    status: null
};

export function createReducer(actionName) {

    const actionNameStates = createActionNameStates(actionName);

    return (state = initialState, action) => {
        switch (action.type) {
            case actionNameStates.request:
                return {
                    ...state,
                    loading: true
                }

            case actionNameStates.success:
                return {
                    ...state,
                    ...action,
                    loading: false,
                    payload: action.payload !== undefined ? action.payload : null
                }

            case actionNameStates.failure:
                return {
                    ...state,
                    ...action,
                    loading: false,
                    error: action.error
                }

            default:
                return state;
        }
    }
}

export function createAction(actionName, fn, cache = false) {
    const actionNameStates = createActionNameStates(actionName);

    if (typeof fn !== 'function') {
        throw new Error('fn must be a function');
    }

    // we are not using arrow function, because there no arguments binding
    // https://developer.mozilla.org/en/docs/Web/JavaScript/Reference/Functions/Arrow_functions
    return function() {
        const args = arguments;
        return (dispatch) => {
            dispatch({
                type: actionNameStates.request
            });

            const result = fn.apply(null, args);

            // It's a promise
            if (typeof result.then === 'function') {
                result.then(response => {
                    dispatch({
                        type: actionNameStates.success,
                        payload: response.data,
                        status: response.status
                    });
                })
                .catch(error => {
                    let obj = {
                        type: actionNameStates.failure,
                        error: null
                    };
                    if(error.response) {

                        dispatch({
                            ...obj,
                            status: error.response.status,
                            error: error.response.data
                        });
                    } else {
                        dispatch({
                            ...obj,
                            error: error
                        });
                    }
                })
            } else {
                dispatch({
                    type: actionNameStates.success,
                    payload: result
                })
            }
        }
    }
}

export function actionTypes(actionName) {
    //assertActionName(actionName);
    return createActionNameStates(actionName);
}