import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import logger from 'redux-logger';
import rootReducer from './reducers/index';
import {saveState, loadState} from "./localStorage";
import throttle from 'lodash/throttle';

const initialState = loadState();
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const middlewares = [thunk];
if (process.env.NODE_ENV === `development`) {
    middlewares.push(logger);
}

export function configureStore() {
    const store = createStore(
        rootReducer,
        initialState,
        composeEnhancers(applyMiddleware(...middlewares))
    )

    store.subscribe(throttle(() => {
        saveState({
            election: {
                filterCandidates: store.getState().election.filterCandidates
            }
        });
    }), 1000)

    return store;
}


