import React from 'react';
import {withRouter, Link, Redirect} from "react-router-dom";
import {connect} from "react-redux";
import {resetElection} from "../../actions/electionActions";
import {resetFilter} from "../../actions/filterActions";
import LinkButton from "../LinkButton";


class ThankYou extends React.Component {

    componentDidMount() {
        window.scrollTo(0, 0);
        this.props.resetElection();
        this.props.resetFilter();
    }

    getCandidate(id) {
        id = parseInt(id);
        return this.props.candidates.find((candidate => {
            return candidate.id === id;
        }));
    }

    render() {
        const candidate = this.getCandidate(this.props.match.params.id)
        if(candidate === undefined) {
            return <Redirect to={"/"} />
        }

        return <div>
            <div>
                <h2>Vielen Dank</h2>
                <p>Du hast deine Stimme <strong>{candidate.firstname} {candidate.lastname}</strong> gegeben.</p>
                <p>
                    <LinkButton className={'w-button w-button-default w-button-icon-left'} to={'/'}>zurück zur Übersicht</LinkButton>
                </p>
            </div>
        </div>
    }
}
const mapStateToProps = state => {
    return {
        candidates: state.election.getCandidates.payload !== null ? state.election.getCandidates.payload.candidates : []
    };
};
export default withRouter(connect(mapStateToProps, { resetElection, resetFilter })(ThankYou));

