import React from 'react';
import VoteForm from './VoteForm';
import {connect} from "react-redux";
import LinkButton from './../LinkButton';
import {Redirect, withRouter} from "react-router-dom";

const IMAGE_URL = process.env.REACT_APP_IMAGE_URL

class Voting extends React.Component {

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    getCandidate(id) {
        id = parseInt(id);
        return this.props.candidates.find((candidate => {
            return candidate.id === id;
        }));
    }

    render() {

        if(this.props.election.state !== 'open') {
            return <Redirect to={'/kandidaten'} />
        }

        if(this.props.candidates.length === 0) return null;

        const candidate = this.getCandidate(this.props.match.params.id);

        return <div className="voting">
            <h1 className="w-headline">
                <span className="w-headline-inner">Wähle jetzt deine/n Kandidat:in</span>
            </h1>
            <div className="row">
                <div className="col-xs-4 col-sm-3" style={{maxWidth: "275px"}}>
                    <img className="img-responsive img-rounded" src={`${IMAGE_URL}${candidate.imagePath}`} ></img>
                </div>
                <div className="col-xs-8 col-sm-9 hint">
                    <p>
                        Du möchtest {candidate.firstname} {candidate.lastname} wählen.<br/>
                        Gib unten deinen Code ein.
                    </p>

                    <LinkButton className={'w-button w-button-default w-button-icon-left'} to={'/kandidaten'}>zurück zur Liste</LinkButton>
                </div>
            </div>
            <VoteForm candidate={candidate}/>
        </div>
    }
}

const mapStateToProps = state => {
    return {
        election: state.election.getElection.payload !== null ? state.election.getElection.payload : null,
        candidates: state.election.getCandidates.payload !== null ? state.election.getCandidates.payload.candidates : []
    };
};

export default withRouter(connect(mapStateToProps, {})(Voting));