import React, {useEffect} from 'react';

import {useDispatch, useSelector} from "react-redux";
import {Link, Redirect, useHistory, useLocation} from 'react-router-dom';

import Filter from './filter/Filter';
import getFilteredCandidates from './filter/candidateFilter';
import moment from "moment";
import {setPageY} from "../../actions/candidateActions";

const IMAGE_URL = process.env.REACT_APP_IMAGE_URL

const getBackgroundImage = ({candidate}) => {
    return <div className="img-candidate" style={{ backgroundImage: `url(${IMAGE_URL}${candidate.imagePath})`}} />
}

const renderCandidates = ({candidates}) => {
    if(candidates.length === 0) {
        return <div className="row" style={{marginTop: '20px'}}>
            <div className="col-xs-12">
                <div className="alert alert-info" role="alert">Es konnte kein/e Kandidat:in gefunden werden, der deinen Suchkriterien entspricht.</div>
            </div>
        </div>
    }

    return candidates
        .map((candidate, i) => {
                return <div className="col-xs-4 col-sm-3 col-md-2 outer-content" key={i}>
                    <Link to={`/kandidat/${candidate.id}`}>
                        <div className="inner-content">
                            {getBackgroundImage({candidate})}
                            <div className="candidate-name">{candidate.firstname}</div>
                        </div>
                    </Link>
                </div>
            }
        );
}


export const CandidateList = () => {

    const dispatch = useDispatch()
    const location = useLocation()

    const {election, candidates, pageY} = useSelector(state => {

        const filter = state.election.filterCandidates.payload !== null ? state.election.filterCandidates.payload.params : null;
        let candidates = [];
        if(state.election.getCandidates.payload !== null) {
            candidates = getFilteredCandidates(state.election.getCandidates.payload.candidates, filter);
        }

        return {
            election: state.election.getElection.payload !== null ? state.election.getElection.payload : null,
            candidates: candidates,
            pageY: state.election.candidatePageY.pageY
        };
    })

    useEffect(() => {
        if(pageY !== 0) {
            window.parent.postMessage({scroll: {y: pageY}}, '*');
        }
    }, [pageY])

    useEffect(() => {

        const handleMessage = (event) => {
            if(location.pathname === '/kandidaten' && event.data.scroll) {
                dispatch(setPageY(event.data.scroll.y))
            }
        }

        window.addEventListener('message', handleMessage, false)

        return _ => {
            window.removeEventListener('message', handleMessage)
        }
    }, [])

    if(!election.showCandidates) {
        return <Redirect to={'/'} />
    }

    return (
        <div>
            <h1 className="w-headline">
                <span className="w-headline-inner">
                    <Link to="/" className="w-back">
                        <svg className="w-back-icon" aria-hidden="true" role="img">
                            <svg viewBox="0 0 1024 1024" width="100%" height="100%">
                            <path d="M830.8 483.4l-34-34c-0.4-0.4-0.8-0.8-1.4-1.2l-437.2-437.2c-15.6-15.6-41-15.6-56.6 0l-34 34c-15.6 15.6-15.6 41 0 56.6l410.4 410.2-410 410c-15.6 15.6-15.6 41 0 56.6l34 34c15.6 15.6 41 15.6 56.6 0l472.2-472.4c15.4-15.6 15.4-41 0-56.6z"></path> </svg>
                        </svg>
                    </Link>
                    <span className="w-text">Kandidat:innenliste {moment().format('Y')}</span>
                </span>
            </h1>
            <Filter />
            <div className="row gallery-container">
                { renderCandidates({candidates}) }
            </div>
        </div>
    )


}
export default CandidateList