const comparison = {
    default: function(candidate, key, value) {
        return candidate[key] !== undefined && candidate[key] === value;
    },
    age: function(candidate, key, value) {
        const parts = value.split(',').map(item => {return parseInt(item)});
        return candidate[key] !== undefined &&
               candidate[key] >= parts[0] && candidate[key] <= parts[1];
    },
    district: function(candidate, key, value) {
        return candidate['district']['id'] === parseInt(value);
    }
};

function filterCandidate(candidate, params) {
    for(let key in params) {
        if(params[key] === '') continue;
        const fn = comparison[key] === undefined ? comparison.default : comparison[key];

        if(!fn(candidate, key, params[key])) {
            return false;
        }
    }
    return true;
}

export default function getFilteredCandidates(candidates, filter) {
    if(filter === null) {
        return candidates;
    }
    let collection = [].concat(candidates);
    collection = collection.filter(function(candidate) {
        return filterCandidate(candidate, filter);
    });
    return collection.sort((a, b) => { return ('' + a.firstname).localeCompare(b.firstname); })
}