import React from 'react';
import CaptchaIcon from "./CaptchaIcon";

import { createCaptcha, submitCaptcha, resetCaptcha } from "../../actions/captchaActions";

import chunk from "lodash/chunk";
import _ from "lodash";
import {connect} from "react-redux";

class Captcha extends React.Component {

    // Set default props
    static defaultProps = {
        onChange: function() {},
        onRenew: function() {}
    };

    constructor(props) {
        super(props);
        this.state = {
            selection: [],
            error: false
        }
    }

    componentDidMount() {
        this.props.createCaptcha();
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.submit.status === 204) {
            this.props.resetCaptcha();
        } else if(nextProps.submit.status === 403) {
            this.setState({error: true});
            this.renewCaptcha();
        }
    }

    handleButtonClick(event) {
        this.setState({error: false});
        this.props.submitCaptcha(this.state.selection);
    }

    handleButtonRenewClick() {
        this.setState({error: false});
        this.renewCaptcha();
    }

    renewCaptcha() {
        this.props.createCaptcha();
        this.setState({selection: []});
        this.props.onChange([]);
        this.props.onRenew();
    }

    clickIcon(index) {
        if(_.includes(this.state.selection, index)) {
            this.setState((prevState) => {
                const newSelection = _.filter(prevState.selection, selected => selected !== index);
                this.props.onChange(newSelection);
                return {
                    selection: newSelection
                }
            });
        } else {
            this.setState((prevState) => {
                const newSelection = [...prevState.selection, index];
                this.props.onChange(newSelection);
                return {
                    selection: newSelection
                }

            });
        }
    }

    renderIcons() {
        let counter = 0;
        const parts = chunk(this.props.captcha.payload.grid, 3);
        return parts.map((row) => { return this.renderIcon(row, counter++)} );
    }

    renderIcon(row, counter) {
        return row.map((group, groupIndex) => {
            let index = (counter * row.length) + groupIndex;
            return <CaptchaIcon key={index} index={index} group={group} onClick={this.clickIcon.bind(this)}/>
        });
    }

    renderTask() {
        const selection = this.props.captcha.payload.selection;
        if(selection.mode === 0) {
            return <div className="exercise-information">
                <div className="exercise">
                    <p>
                        Klicke auf alle Bilder, die
                    </p>
                    <ul>
                        <li>die Hintergrundfarbe <span className="info-clickable-element">{selection.b.toLowerCase()}</span> haben</li>
                        <li>und zusätzlich auf alle Bilder auf denen ein(e) <span className="info-clickable-element">{selection.f}</span> zu sehen ist</li>
                    </ul>
                </div>
            </div>
        }

        return ( <h1>Klicke alle Bilder an auf dem ein {selection.b.toLowerCase()}er Hintergrund mit einem {selection.f} zu sehen ist.</h1>);
    }

    renderCaptchaValidation() {
        if(!this.state.error) {
            return null;
        }

        return (
            <div className="alert alert-danger">Leider war deine Lösung falsch. Probiere es noch einmal. Achtung, die Aufgabe ist jetzt neu!</div>
        );
    }

    insertStyle() {
        const oldStyleNode = document.getElementById('captcha-style');
        if(oldStyleNode) {
            oldStyleNode.parentNode.removeChild(oldStyleNode);
        }
        const head = document.getElementsByTagName('head')[0];
        const node = document.createElement("style");
        node.setAttribute('id', 'captcha-style');
        node.setAttribute('type', 'text/css');
        node.appendChild(document.createTextNode(this.props.captcha.payload.stylesheet));
        head.appendChild(node);
    }

    render() {
        if(!this.props.captcha.loading && this.props.captcha.payload === null) return null;
        if(this.props.captcha.loading) {
            return (<div>Lade Captcha....</div>);
        }
        this.insertStyle();
        return (
            <div className="captcha">
                {this.renderCaptchaValidation()}
                {this.props.children}
                { this.renderTask() }

                <div className="grid">{ this.renderIcons() }
                    <div className="clearfix"></div>
                </div>

                <div className="actions">
                    <button disabled={this.props.submit.loading} type="button" className="w-button w-button-primary w-button-icon-left"
                            onClick={this.handleButtonClick.bind(this)}
                    >
                        <svg className="w-button-icon" aria-hidden="true" role="img">
                            <svg viewBox="0 0 1024 1024" width="100%" height="100%">
                                <path d="M830.8 483.4l-34-34c-0.4-0.4-0.8-0.8-1.4-1.2l-437.2-437.2c-15.6-15.6-41-15.6-56.6 0l-34 34c-15.6 15.6-15.6 41 0 56.6l410.4 410.2-410 410c-15.6 15.6-15.6 41 0 56.6l34 34c15.6 15.6 41 15.6 56.6 0l472.2-472.4c15.4-15.6 15.4-41 0-56.6z"></path> </svg>
                        </svg>
                        <span>{this.props.submit.loading ? 'warte...' : 'Captcha lösen'}</span>
                    </button>

                    <button type="button" className="w-button w-button-default w-button-icon-left"
                            onClick={this.handleButtonRenewClick.bind(this)}
                    >
                        <svg className="w-button-icon" aria-hidden="true" role="img">
                            <svg viewBox="0 0 1024 1024" width="100%" height="100%">
                                <path d="M830.8 483.4l-34-34c-0.4-0.4-0.8-0.8-1.4-1.2l-437.2-437.2c-15.6-15.6-41-15.6-56.6 0l-34 34c-15.6 15.6-15.6 41 0 56.6l410.4 410.2-410 410c-15.6 15.6-15.6 41 0 56.6l34 34c15.6 15.6 41 15.6 56.6 0l472.2-472.4c15.4-15.6 15.4-41 0-56.6z"></path> </svg>
                        </svg>
                        <span>Captcha neu laden</span>
                    </button>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        captcha: state.captcha.createCaptcha,
        submit: state.captcha.submitCaptcha
    };
};

const mapDispatchToProps = { createCaptcha, submitCaptcha, resetCaptcha };
export default connect(mapStateToProps, mapDispatchToProps)(Captcha);