import React from 'react';
import config from "../../config";
import {getSchools} from '../../actions/schoolActions';
import {voteCandidate} from '../../actions/electionActions';
import {statusCaptcha, resetCaptcha} from '../../actions/captchaActions';
import {connect} from "react-redux";
import Captcha from './../captcha/Captcha';
import {Redirect, withRouter} from 'react-router-dom';

class VoteForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            form: {
                tan: '',
                school: '',
                age: '',
                gender: '',
                candidate: props.candidate.id
            },
            errors: {
                tan: false
            }
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        this.props.getSchools(config.slug);
        this.props.statusCaptcha();
    }

    handleChange(event) {
        const name = event.target.name;
        const value = event.target.value;
        this.setState((prevState) => {
            return {
                form: {
                    ...prevState.form,
                    [name]: value
                }
            }
        });
    }

    handleSubmit(event) {
        event.preventDefault();
        if(this.state.form.tan === '') {
            this.setState((prevState) => {
                return {
                    errors: {
                        ...prevState.errors,
                        ['tan']: true
                    }
                }
            });
        } else {
            this.setState((prevState) => {
                return {
                    errors: {
                        ...prevState.errors,
                        ['tan']: false
                    }
                }
            });
            this.props.voteCandidate(config.slug, {...this.state.form, electionId: this.props.election.id});
        }
    }

    renderError(name) {
        if(!this.props.formErrors) return;

        if(this.props.formErrors[name]) {
            return <div className="vote-error">{this.props.formErrors[name][0].message}</div>
        }
    }

    renderTan() {
        let formGroupClasses = 'form-group';
        if(this.state.errors.tan) {
            formGroupClasses += ' has-error';
        }
        return <div className={formGroupClasses}>
            <label htmlFor="tan" className="col-sm-3 control-label">Dein Code</label>
            <div className="col-sm-6">
                <input id="tan" name="tan" type="text" className="form-control" onChange={this.handleChange} placeholder="xxxx-xxxx-xxxx" maxLength={14} />
                {this.renderError('tan')}
                {this.state.errors.tan == true && <div className="vote-error">Bitte gib deinen Code ein</div>}
                <p className="help-block">Bitte achte hier auf Groß- und Kleinschreibung.</p>
            </div>
        </div>
    }

    renderSchools() {
        const schools = this.props.schools.map((school) => {
            return <option key={school.id} value={school.id}>{school.name}</option>
        });
        return (
            <div className="form-group">
                <label htmlFor="school" className="col-xs-12 col-sm-3 control-label">Deine Schule (freiwillig)</label>
                <div className="col-xs-12 col-sm-6">
                    <select id="school" name="school" className="form-control" onChange={this.handleChange}>
                        <option value=""></option>
                        { schools }
                    </select>
                </div>
            </div>
        );
    }

    renderAge() {
        let options = Array.from({length: 8}, (v, k) => k+14).map(i => {
            return <option key={i} value={i}>{i}</option>
        });
        return <div className="form-group">
            <label htmlFor="age" className="col-xs-12 col-sm-3 control-label">Dein Alter (freiwillig)</label>
            <div className="col-xs-12 col-sm-6">
                <select id="age" name="age" className="form-control" onChange={this.handleChange}>
                    <option value=""></option>
                    {options}
                </select>
            </div>
        </div>
    }

    renderGender() {
        return <div className="form-group">
            <label htmlFor="gender" className="col-xs-12 col-sm-3 control-label">Dein Geschlecht (freiwillig)</label>
            <div className="col-xs-12 col-sm-6">
                <select id="gender" name="gender" className="form-control" onChange={this.handleChange}>
                    <option value=""></option>
                    <option value="f">weiblich</option>
                    <option value="m">männlich</option>
                    <option value="d">divers</option>
                </select>
            </div>
        </div>
    }

    renderCaptcha() {
        if(!this.props.createCaptcha) return;

        return <div>
            <h1 className="w-headline">
                <span className="w-headline-inner">Du hattest leider zu viele Falscheingaben.</span>
            </h1>
            {this.renderError('captcha')}
            <Captcha><strong>Bitte löse die folgende Aufgabe, um zu zeigen, dass du kein Computer bist:</strong></Captcha>
        </div>
    }

    isSubmitButtonEnabled() {
        const tan = this.state.form.tan;
        return (tan.length === 12 && tan.indexOf('-') === -1) ||
               (tan.length === 14 && tan.charAt(4) === '-' && tan.charAt(9) === '-') ||
               !this.props.formSubmitted

    }

    renderCaptchaSuccess() {
        if(this.props.submitCaptcha.status !== 204) return;

        return <div className="alert alert-success">Die Aufgabe wurde erfolgreich gelöst. Bitte gib jetzt deinen Code erneut ein.</div>
    }

    render() {
        if(this.props.schools.length === 0) return null;

        if(this.props.voteSuccess) {
            return <Redirect to={"/danke/" + this.props.candidate.id}/>
        }

        return <div className="voting-form">

            {!this.props.createCaptcha &&
            <form onSubmit={this.handleSubmit} className="form-horizontal">
                <h2 className="w-headline">
                    <span className="w-headline-inner">Wahlformular</span>
                </h2>
                {this.renderCaptchaSuccess()}
                {this.props.formErrors || this.state.errors.tan ?
                    <div className={"alert alert-danger"} role="alert">Du hast Fehler im Formular. Schau dir die Felder
                        an.</div> : ''}

                {this.renderTan()}
                {this.renderSchools()}
                {this.renderAge()}
                {this.renderGender()}
                <div className="form-group">
                    <div className="col-sm-offset-3 col-sm-6">
                        <button disabled={!this.isSubmitButtonEnabled()}
                                type="submit" className="w-button w-button-primary w-button-icon-left"
                        >
                            <svg className="w-button-icon" aria-hidden="true" role="img">
                                <svg viewBox="0 0 1024 1024" width="100%" height="100%">
                                    <path
                                        d="M830.8 483.4l-34-34c-0.4-0.4-0.8-0.8-1.4-1.2l-437.2-437.2c-15.6-15.6-41-15.6-56.6 0l-34 34c-15.6 15.6-15.6 41 0 56.6l410.4 410.2-410 410c-15.6 15.6-15.6 41 0 56.6l34 34c15.6 15.6 41 15.6 56.6 0l472.2-472.4c15.4-15.6 15.4-41 0-56.6z"></path>
                                </svg>
                            </svg>
                            <span>Jetzt deine Stimme abgeben</span>
                        </button>
                    </div>
                </div>
            </form>
            }
            {this.renderCaptcha()}
        </div>
    }
}

const mapStateToProps = state => {
    return {
        election: state.election.getElection.payload !== null ? state.election.getElection.payload : null,
        schools: state.election.getSchools.payload !== null ? state.election.getSchools.payload : [],
        createCaptcha: state.election.voteCandidate.status === 401 || (state.captcha.statusCaptcha.payload !== null
            ? state.captcha.statusCaptcha.payload.created : false),
        formSubmitted: state.election.voteCandidate.loading,
        formErrors: state.election.voteCandidate.errors !== null && state.election.voteCandidate.status === 400
            ? state.election.voteCandidate.error.errors : false,
        submitCaptcha: state.captcha.submitCaptcha,
        voteSuccess: state.election.voteCandidate.status === 204
    };
};

const mapStateToDispatch = {
    getSchools,
    voteCandidate,
    statusCaptcha,
    resetCaptcha
}

export default withRouter(connect(mapStateToProps, mapStateToDispatch)(VoteForm));
