import React from 'react'
import LinkButton from './LinkButton'

const NoMatch = () => {
    return <div className="col-xs-12 col-sm-8">
        <h1 className="w-headline"><span>Die aufgerufene Seite konnte nicht gefunden werden</span></h1>
        <div>
            <LinkButton to="/kandidaten" >zurück zur Liste</LinkButton>
        </div>
    </div>
}
export default NoMatch