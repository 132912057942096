import React from 'react'

import config from './config';
import {connect} from "react-redux";
import {Route, Switch} from 'react-router';
import { withRouter } from 'react-router-dom';
import {getElection} from "./actions/electionActions";
import {getCandidates} from "./actions/candidateActions";

import Election from './components/election/Election';
import CandidateList from "./components/election/CandidateList";
import Candidate from "./components/election/Candidate";
import ThankYou from "./components/election/ThankYou";
import NoMatch from "./components/NoMatch";
import Voting from "./components/election/Voting";
import Imprint from "./components/wuppertal/Imprint";
import Privacy from "./components/wuppertal/Privacy";
import {getSnippets} from "./actions/snippetActions";

class App extends React.Component {
    componentDidMount() {
        this.props.getElection(config.slug);
        this.props.getSnippets(config.slug);
        this.props.getCandidates(config.slug);
    }

    render() {
        if(this.props.election === null || this.props.candidates === null) return null;
        return (
            <div className="w-outer-content">
                <div className="w-content">
                <Switch>
                    <Route exact path='/' component={Election}/>
                    <Route exact path='/kandidaten' component={CandidateList} />
                    <Route exact path='/kandidat/:id' component={Candidate} />
                    <Route exact path='/waehlen/:id' component={Voting} />
                    <Route exact path='/danke/:id' component={ThankYou} />
                    <Route exact path='/impressum' component={Imprint} />
                    <Route exact path='/datenschutzerklaerung' component={Privacy} />
                    <Route component={NoMatch}/>
                </Switch>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        election: state.election.getElection.payload !== null ? state.election.getElection.payload : null,
        snippets: state.election.getSnippets.payload !== null ? state.election.getSnippets.payload : {},
        candidates: state.election.getCandidates.payload !== null ? state.election.getCandidates.payload.candidates : []
    };
};
const mapDispatchToProps = { getElection, getCandidates, getSnippets }
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
