import React from 'react';
import Moment from 'react-moment';
import {connect} from "react-redux";
import { Link } from 'react-router-dom';
import Arrow from './../wuppertal/Arrow'
import moment from "moment";
import Snippet from "../Snippet";

class Election extends React.Component {

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    renderCandidateLink() {
        return (
            <Link to='/kandidaten' className="w-jumbo-button">

                <Arrow />
                <div>
                    <h1 className="w-button-headline">
                        Hier sind deine Kandidat:innen.
                    </h1>
                    <div className="w-info">Kandidat:innen {moment().format('Y')}</div>
                </div>
            </Link>
        );
    }

    renderShowCandidates() {
        return (
            <>
                &nbsp;Du kannst dir aber jetzt schon alle Kandidat:innen hier anschauen:
                <br/><br/>
                { this.renderCandidateLink() }
            </>
        )
    }

    renderElectionState() {
        if(this.props.election.state === 'past') {
            return <p>
                <strong>Die Plattform ist geschlossen.</strong> Sie war vom {<Moment format="DD.MM.YYYY HH:mm">{ this.props.election.start }</Moment>} Uhr
                bis {<Moment format="DD.MM.YYYY HH:mm">{ this.props.election.end }</Moment>} Uhr geöffnet.
            </p>
        }

        if(this.props.election.state === 'open') {
            return <p>
                Die Plattform ist bis <strong>{<Moment format="DD.MM.YYYY HH:mm">{this.props.election.end}</Moment>}</strong> Uhr geöffnet.
                <br/><br/>
                { this.renderCandidateLink() }
            </p>
        }

        if(this.props.election.state === 'future') {
            return <p>
                <strong>Die Plattform ist noch geschlossen.</strong> Sie wird vom {<Moment format="DD.MM.YYYY HH:mm">{ this.props.election.start }</Moment>} Uhr bis zum {<Moment format="DD.MM.YYYY HH:mm">{ this.props.election.end }</Moment>} Uhr zum Wählen geöffnet sein.
                {this.props.election.showCandidates && this.renderShowCandidates()}
            </p>
        }
    }

    render() {
        return <div className="row">
            <div className="col-xs-12 col-sm-8">
                    <h1 className="w-headline">
                        <span className="w-headline-inner">Wuppertaler Jugendratswahl {moment().format('Y')}</span>
                    </h1>
                <div className="w-text">
                    <p>
                        Die Wahlen zum Jugendrat finden
                        vom {<Moment format="DD.MM.YYYY">{ this.props.election.start}</Moment>} - {<Moment format="DD.MM.YYYY">{ this.props.election.end}</Moment>} statt.<br/><br/>
                        Jede/r Jugendliche zwischen 14 – einschließlich 21 Jahren, der/die
                        mindestens seit 3 Monaten in Wuppertal gemeldet ist, hat 1 Stimme.
                        Mit dem persönlichen Code, der dir per Post zugestellt wurde,
                        kannst du deine Stimme vergeben. Der Code ist nur einmal gültig.
                    </p>
                    { this.renderElectionState() }
                    <div className="w-box">
                        <h1 className="w-box-headline">Wahlhotline</h1>
                        <p>
                            <Snippet identifier={'wahlhotline-text'} />

                            {this.props.snippets['wahlhotline-telefonnummer'] &&
                            <a href={`tel:${this.props.snippets['wahlhotline-telefonnummer'].content}`}>
                                <svg className="w-box-icon" aria-hidden="true" role="img">
                                    <symbol viewBox="0 0 128 128" id="SPi-contact" style={{display: 'block'}}>
                                        <path
                                            d="M128.2 64.3c0-19.3-19.8-34.8-41.2-35 30.7 30.7-5.5 63.2-35 63.2 0 0 3.4 6.7 29.3 6.7a63 63 0 0 0 8.5-.6l28.4 11-9.4-17c11.8-6.5 19.4-16.8 19.4-28.3z"></path>
                                        <path
                                            d="M93.6 53.4c0-19.2-21-35-47-35S0 34.3 0 53.5c0 11.6 7.6 22 19.3 28.3l-9.3 17 28.4-11a62.5 62.5 0 0 0 8.6.6c26 0 47-15.6 47-35zm-61.5.2a5.6 5.6 0 0 1-5.3 5.4h-.2a5.6 5.6 0 1 1 5.6-5.6v.2zm20.6 0A5.6 5.6 0 0 1 47 59a5.6 5.6 0 1 1 5.6-5.6v.2zm20.4 0a5.6 5.6 0 0 1-5.5 5.4 5.6 5.6 0 1 1 5.5-5.6v.2z"></path>
                                    </symbol>
                                </svg>
                                {this.props.snippets['wahlhotline-telefonnummer'].content }
                            </a>
                            }
                        </p>

                    </div>
                </div>
            </div>
        </div>
    }
}
const mapStateToProps = state => {
    return {
        election: state.election.getElection.payload !== null ? state.election.getElection.payload : null,
        snippets: state.election.getSnippets.payload || {}
    };
};
export default connect(mapStateToProps, {})(Election);
