import { combineReducers } from 'redux';
import { createReducer, initialState } from '../reduxHelper'
import {pageYReducer} from '../actions/candidateActions'

const captchaReducers = combineReducers({
    statusCaptcha: createReducer('STATUS_CAPTCHA'),
    createCaptcha: createReducer('CREATE_CAPTCHA'),
    submitCaptcha: createReducer('SUBMIT_CAPTCHA')
});

const electionReducers = combineReducers({
    getSnippets: createReducer('GET_SNIPPETS'),
    getElection: createReducer('GET_ELECTION'),
    voteCandidate: createReducer('VOTE_CANDIDATE'),
    getCandidates: createReducer('GET_CANDIDATES'),
    getCandidate: createReducer('GET_CANDIDATE'),
    filterCandidates: createReducer('FILTER_CANDIDATES'),
    getSchools: createReducer('GET_SCHOOLS'),
    candidatePageY: pageYReducer
})

const resetElectionReducers = (state, action) => {
    if(action.type === 'CAPTCHA_RESET_SUCCESS') {
        state = {
            ...state,
            voteCandidate: initialState,
        }
    }

    if(action.type === 'RESET_ELECTION_SUCCESS') {
        state = {
            ...state,
            voteCandidate: initialState
        }
    }

    if(action.type === 'RESET_FILTER_SUCCESS') {
        state = {
            ...state,
            filterCandidates: initialState
        }
    }

    return electionReducers(state, action);
}

const resetCaptchaReducers = (state, action) => {
    if(action.type === 'CREATE_CAPTCHA_REQUEST') {
        state = {
            ...state,
            submitCaptcha: initialState
        }
    }

    if(action.type === 'CAPTCHA_RESET_SUCCESS') {
        state = {
            ...state,
            statusCaptcha: {
                ...initialState,
                payload: {created: false}
            },
            createCaptcha: initialState
        }
    }
    return captchaReducers(state, action);
}

export default combineReducers({
    captcha: resetCaptchaReducers,
    election: resetElectionReducers
});