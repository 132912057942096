import React from 'react';
import {connect} from "react-redux";
import {filterCandidates} from "../../../actions/filterActions";

class Filter extends React.Component {
    constructor(props) {
        super(props);

        this.handleChange = this.handleChange.bind(this);
        this.toggleFilter = this.toggleFilter.bind(this);

        this.gender = [
            {value: 'f', label: 'weiblich'},
            {value: 'm', label: 'männlich' },
            {value: 'd', label: 'divers' }
        ];

        this.age = [
            {value: '14,16', label: '14-16'},
            {value: '17,18', label: '17-18'},
            {value: '19,21', label: '19-21'}
        ];
    }

    handleChange(event) {
        const name =  event.target.name;
        const value = event.target.value;

        let obj = {};
        obj[name] = value;

        this.props.filterCandidates({
           isOpen: this.props.filter.isOpen,
           params: {...this.props.filter.params, ...obj}
        });
    }

    toggleFilter(event) {
        this.props.filterCandidates({
            isOpen: !this.props.filter.isOpen,
            params: this.props.filter.params
        });
    }

    getValue(name) {
        return this.props.filter !== null ? this.props.filter.params[name] : '';
    }

    isOpen() {
        return this.props.filter !== null && this.props.filter.isOpen;
    }

    renderDistrict() {
        const districtOptions = this.props.districts.map((district, i) => {
            return <option key={district.id} value={district.id}>{district.name}</option>;
        })

        return <div className="form-group">
            <label className="control-label">Stadtteil</label>

                <select name="district" value={this.getValue('district')} onChange={this.handleChange} className="form-control">
                    <option value=""></option>
                    { districtOptions }
                </select>

        </div>
    }

    renderGender() {
        const options = this.gender.map((item) => {
            return <option key={item.value} value={item.value}>{item.label}</option>
        });
        return <div className="form-group">
            <label className="control-label">Geschlecht</label>

                <select name="gender" value={this.getValue('gender')} onChange={this.handleChange} className="form-control">
                <option value=""></option>
                { options }
                </select>

        </div>
    }

    renderAge() {
        const options = this.age.map((item) => {
            return <option key={item.value} value={item.value}>{item.label}</option>
        });
        return <div className="form-group">
            <label className="control-label">Alter</label>

                <select value={this.getValue('age')} name="age" onChange={this.handleChange} className="form-control">
                <option value=""></option>
                {options}
                </select>

        </div>
    }

    render() {
        let toggleClasses = ['w-toggle'];
        if(this.isOpen()) {
            toggleClasses.push('w-toggle-open');
        } else {
            toggleClasses.push('w-toggle-closed')
        }
        return <div className="filter">
            <div className={toggleClasses.join(' ')} onClick={this.toggleFilter}>
                <span>Filter</span>
                <span className="w-back">
                    <svg className="w-back-icon" aria-hidden="true" role="img">
                        <svg viewBox="0 0 1024 1024" width="100%" height="100%">
                            <path d="M830.8 483.4l-34-34c-0.4-0.4-0.8-0.8-1.4-1.2l-437.2-437.2c-15.6-15.6-41-15.6-56.6 0l-34 34c-15.6 15.6-15.6 41 0 56.6l410.4 410.2-410 410c-15.6 15.6-15.6 41 0 56.6l34 34c15.6 15.6 41 15.6 56.6 0l472.2-472.4c15.4-15.6 15.4-41 0-56.6z"></path> </svg>
                    </svg>
                </span>

            </div>

            <div className={this.isOpen() ? "toggle-content-show" : "toggle-content-hide"}>
                <form className="form-inline">
                    {this.renderDistrict()}
                    {this.renderGender()}
                    {this.renderAge()}
                </form>
            </div>
        </div>
    }
}

const mapStateToProps = state => {
    return {
        districts: state.election.getCandidates.payload !== null ? state.election.getCandidates.payload.districts : [],
        filter: state.election.filterCandidates.payload !== null ? state.election.filterCandidates.payload : {
            isOpen: false,
            params: {}
        }
    };
};
export default connect(mapStateToProps, { filterCandidates })(Filter);