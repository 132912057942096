import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom';

const LinkButton = (props) => {
    const children = props.children;
    return (
        <Link {...props}>
            <svg className="w-button w-button-icon" aria-hidden="true" role="img">
                <svg viewBox="0 0 1024 1024" width="100%" height="100%">
                    <path d="M830.8 483.4l-34-34c-0.4-0.4-0.8-0.8-1.4-1.2l-437.2-437.2c-15.6-15.6-41-15.6-56.6 0l-34 34c-15.6 15.6-15.6 41 0 56.6l410.4 410.2-410 410c-15.6 15.6-15.6 41 0 56.6l34 34c15.6 15.6 41 15.6 56.6 0l472.2-472.4c15.4-15.6 15.4-41 0-56.6z"></path> </svg>
            </svg>
            <span>{children}</span>
        </Link>
    )
};


LinkButton.propTypes = {
    to: PropTypes.string.isRequired,
};

export default LinkButton;