import { createAction } from "../reduxHelper";
import axios from "./../axios";

export const statusCaptcha = createAction('STATUS_CAPTCHA', function() {
    return axios.get('/captcha/status', {cache: false});
});

export const createCaptcha = createAction('CREATE_CAPTCHA', function() {
    return axios.get('/captcha/create', {cache: false});
});

export const submitCaptcha = createAction('SUBMIT_CAPTCHA', function(selection) {
    let data = JSON.stringify({
        selection: selection
    })
    return axios.post('/captcha/submit', data, {cache: false});
});

export const resetCaptcha = createAction('CAPTCHA_RESET', function(selection) {
    return true;
});